import styled from 'styled-components'

export const FilterForm = styled.form`
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 0.5em;
  font-weight: bold;
  row-gap: 0.5em;
  align-items: center;
`

export const Label = styled.label`
  display: flex;
  font-weight: normal;
  padding-left: 2em;
  padding-right: 2em;
`

export const Input = styled.input`
  margin-left: 0.7em;
`

export const Select = styled.select`
  border: none;
  cursor: pointer;
  background-color: white;
  border: 1px solid #ededf0;
`
