import React, { useState, useCallback } from 'react'
import debounce from 'lodash.debounce'
import { FilterForm, Label, Input } from './styles.js'
import { EmPillSingleSelect } from '@scvsoft/emerald-react'
import { SelectOption } from '@scvsoft/emerald'

interface FilterLinksFormProps {
  filter: (firstName: string, lastName: string, hasExpired: string | undefined) => Promise<void>
}

type ExpiredType = SelectOption

const options: ExpiredType[] = [
  { value: 'true', label: 'Expired' },
  { value: 'false', label: 'Non Expired' },
]

const FilterLinksForm = ({ filter }: FilterLinksFormProps) => {
  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [hasExpired, setHasExpired] = useState<ExpiredType | undefined>(undefined)

  const debouncedFilter = useCallback(
    debounce(
      (firstNameSearch, lastNameSearch, hasExpired) => {
        filter(firstNameSearch, lastNameSearch, hasExpired?.value)
      },
      1000,
      { leading: true }
    ),
    []
  )

  const updateFirstName = (newFirstName: string) => {
    debouncedFilter(newFirstName, lastName, hasExpired)
    setFirstName(newFirstName)
  }

  const updateLastName = (newLastName: string) => {
    debouncedFilter(firstName, newLastName, hasExpired)
    setLastName(newLastName)
  }

  const updateExpired = (newHasExpired: ExpiredType) => {
    debouncedFilter(firstName, lastName, newHasExpired)
    setHasExpired(newHasExpired)
  }

  return (
    <FilterForm>
      <Label htmlFor="firstName">
        FirstName:
        <Input id="firstName" onChange={(e) => updateFirstName(e.target.value)} />
      </Label>
      <Label htmlFor="lastName">
        LastName:
        <Input id="lastName" onChange={(e) => updateLastName(e.target.value)} />
      </Label>
      <EmPillSingleSelect
        label="Expiration"
        options={options}
        selected={hasExpired}
        onEmChange={({ detail }) => updateExpired(detail)}
        icon="fa-calendar"
      />
    </FilterForm>
  )
}

export default FilterLinksForm
